// import React, { useState, useEffect } from 'react';
// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './components/Home';
// import Footer from './components/Footer';
// import Services from './pages/services';
// import TalentAcquisition from './skills/talent';
// import PermanentRecruitment from './skills/pr';
// import ContractStaffing from './skills/contract';
// import RPOandMSP from './skills/rpo';
// import ExecutiveSearch from './skills/executive';
// import PayrollServices from './skills/payroll_services'; 
// import EmployerBranding from './skills/employer';
// import PlacementEvents from './skills/placement';
// import SkillDevelopment from './skills/skill';
// import TermsOfService from './company_terms/terms';
// import PrivacyPolicy from './company_terms/policy';
// import CopyrightPage from './company_terms/copyright';
// import EnrollmentForm from './pages/enroll';
// import RecruitmentForm from './pages/recruitment';
// import Programs from './pages/program';
// import ContactPage from './pages/contact';
// import Preloader from '../src/components/preloader';
// import AboutPage from '../src/pages/about';
// import CustomNavbar from './components/Navbar';
// import Copydev from './components/copydev';
// import ITESPrograms from './industries/it';
// import ECommerce from './industries/ecommerce';
// import EnergySector from './industries/energy';
// import BankingFinance from './industries/banking';
// import ManufacturingLogistics from './industries/manufra';
// import PharmaSector from './industries/health';

// function App() {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setLoading(false);  
//     }, 2000);

//     return () => clearTimeout(timer);
//   }, []);

//   if (loading) {
//     return <Preloader />;
//   }

//   return (

//     <Router>
//       <CustomNavbar/>
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/services" element={<Services />} />
//         <Route path="/talent-acquisition" element={<TalentAcquisition />} />
//         <Route path="/permanent-recruitment" element={<PermanentRecruitment />} />
//         <Route path="/contract-staffing" element={<ContractStaffing />} />
//         <Route path="/RPO-MSP" element={<RPOandMSP />} />
//         <Route path="/executive-search" element={<ExecutiveSearch />} />
//         <Route path="/payroll-services" element={<PayrollServices />} />
//         <Route path="/Employer-Talent" element={<EmployerBranding />} />
//         <Route path="/placement-events" element={<PlacementEvents />} />
//         <Route path="/skill-development" element={<SkillDevelopment />} />
//         <Route path="/programs" element={<Programs />} />
//         <Route path="/terms" element={<TermsOfService />} />
//         <Route path="/contact" element={<ContactPage />} />
//         <Route path="/about" element={<AboutPage />} />
//         <Route path="/policy" element={<PrivacyPolicy />} />
//         <Route path="/copy" element={<CopyrightPage />} />
//         <Route path="/enroll" element={<EnrollmentForm />} />
//         <Route path="/recruitment" element={<RecruitmentForm />} />
//         <Route path="/ites"  element={<ITESPrograms/>} />
//         <Route path="/ecommerce" element={<ECommerce/>} />
//         <Route path="/energy" element={<EnergySector/>} />
//         <Route path="/bank" element={<BankingFinance/>} />
//         <Route path="/mli" element={<ManufacturingLogistics/>} />
//         <Route path="/pharma" element={<PharmaSector/>} />


//       </Routes>
//        <Footer /> 
//        <Copydev/>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
  
    
   
        <Container fluid className="p-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <Alert variant="danger" className="text-center">
                <h1>Service Error: Google Service Issue Detected</h1>
                <p>We're currently experiencing a service issue related to Google services. This may cause interruptions in functionality.</p>
              </Alert>
    
              <Alert variant="warning" className="mt-4">
                <h1>Policy Violation</h1>
                <p>
                  Your action may have violated Google's policies. Please ensure you are in compliance with the following Google policies:
                </p>
                <ul>
                  <li>Policy 1: Data usage must align with Google's Privacy Guidelines.</li>
                  <li>Policy 2: Services cannot be used for unauthorized tracking or data collection.</li>
                  <li>Policy 3: Any form of scraping is prohibited under the Google Services Agreement.</li>
                </ul>
              </Alert>
    
              <Alert variant="info" className="mt-4">
                <h1>Resolution</h1>
                <p>
                  Please follow Google's guidelines to resolve these issues. You can check more details at{' '}
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    Google Policies
                  </a>.
                  <p className="mb-0">
        <small>&copy; {new Date().getFullYear()} OlewintechSolutions. All rights reserved.</small>
      </p>
                </p>
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    };
    
 

export default App
